.page-layout {
  width: 100%;
  animation: fadeIn 0.5s ease;
}

.page-content {
  width: 100%;
}

.profile-section {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 3rem;
}

.profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 8px 16px var(--shadow-color);
  transition: transform var(--transition-speed) ease;
}

.profile-image:hover {
  transform: scale(1.05);
}

.social-links {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem 0;
}

.page-header {
  position: relative;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-title {
  margin: 0;
  font-size: 2rem;
  color: var(--text-primary);
}

@media (max-width: 768px) {
  .profile-section {
    flex-direction: column;
    text-align: center;
  }

  .profile-image {
    width: 150px;
    height: 150px;
  }

  .page-header {
    padding: 0.75rem;
  }
  
  .page-title {
    font-size: 1.5rem;
  }
} 