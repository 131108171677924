.section {
  background-color: var(--background-secondary);
  border-radius: var(--section-border-radius);
  padding: 2rem;
  margin: 1rem 0;
  box-shadow: 0 4px 6px var(--shadow-color);
  transition: all var(--transition-speed) ease;
  position: relative;
  overflow: hidden;
}

.section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    var(--accent-primary),
    transparent
  );
  opacity: 0;
  transition: opacity var(--transition-speed) ease;
}

.section:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 12px var(--shadow-color);
}

.section:hover::before {
  opacity: 1;
}

.section-content {
  position: relative;
  z-index: 1;
}

.section-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--text-primary);
}

.section-text {
  color: var(--text-secondary);
  line-height: 1.6;
}

/* Variants */
.section.highlight {
  background-color: var(--background-tertiary);
  border: 1px solid var(--accent-primary);
}

.section.transparent {
  background-color: transparent;
  box-shadow: none;
}

@media (max-width: 768px) {
  .section {
    padding: 1.5rem;
  }
  
  .section-title {
    font-size: 1.25rem;
  }
} 