.role-box {
  background: var(--background-secondary);
  border-radius: 16px;
  padding: 1.5rem;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid var(--border-color);
  margin-bottom: 1.5rem;
}

.role-box:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px var(--shadow-color);
}

.company-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.25rem;
}

.company-logo-wrapper {
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  border-radius: 10px;
  overflow: hidden;
  background: var(--background-tertiary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 4px;
}

.company-info {
  flex: 1;
  min-width: 0;
}

.company-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
  line-height: 1.3;
}

.role-section {
  padding-left: 1rem;
  border-left: 2px solid var(--border-color);
  margin-top: 1rem;
}

.role-title {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--text-primary);
  margin: 0 0 0.5rem 0;
}

.role-meta {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
}

.highlights {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 0.75rem 0;
}

.highlight-tag {
  background: var(--background-tertiary);
  color: var(--accent-primary);
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.85rem;
  font-weight: 500;
}

.bullet-points {
  list-style: none;
  padding: 0;
  margin: 0.75rem 0 0 0;
}

.bullet-points li {
  position: relative;
  padding-left: 1.25rem;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
  font-size: 0.95rem;
  line-height: 1.5;
}

.bullet-points li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--accent-primary);
}

@media (max-width: 768px) {
  .role-box {
    padding: 1.25rem;
  }

  .company-logo-wrapper {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }

  .company-name {
    font-size: 1.1rem;
  }

  .role-title {
    font-size: 1rem;
  }

  .bullet-points li {
    font-size: 0.9rem;
  }
}

.company-logo-wrapper::before {
  display: none;
}

@keyframes shimmer {
  display: none;
}

