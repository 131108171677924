.navbar {
  position: sticky;
  top: 0;
  backdrop-filter: blur(20px);
  background-color: var(--background-primary);
  border-bottom: 1px solid var(--border-color);
  z-index: 100;
  height: var(--nav-height);
}

.navbar-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--text-primary);
  text-decoration: none;
  z-index: 2;
}

.navbar-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.navbar-links a {
  color: var(--text-primary);
  text-decoration: none;
  font-weight: 500;
  transition: opacity 0.2s ease;
  position: relative;
}

.navbar-links a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--accent-primary);
  border-radius: 2px;
}

.navbar-links a:hover {
  opacity: 0.7;
}

.theme-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1.25rem;
}

.mobile-menu-button {
  display: none;
}

@media (max-width: 768px) {
  .mobile-menu-button {
    display: block;
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    z-index: 2;
  }

  .menu-icon {
    display: block;
    width: 24px;
    height: 2px;
    background-color: var(--text-primary);
    position: relative;
    transition: background-color 0.3s;
  }

  .menu-icon::before,
  .menu-icon::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: var(--text-primary);
    transition: transform 0.3s;
  }

  .menu-icon::before {
    top: -6px;
  }

  .menu-icon::after {
    bottom: -6px;
  }

  .navbar-links {
    position: absolute;
    top: var(--nav-height);
    right: 0;
    background: var(--background-primary);
    padding: 0.5rem;
    flex-direction: column;
    min-width: 200px;
    transform-origin: top;
    transform: scaleY(0);
    opacity: 0;
    transition: transform 0.2s ease, opacity 0.2s ease;
    box-shadow: 0 4px 12px var(--shadow-color);
    border: 1px solid var(--border-color);
    border-top: none;
    border-radius: 0 0 8px 8px;
  }

  .navbar-links.open {
    transform: scaleY(1);
    opacity: 1;
  }

  .navbar-links a {
    padding: 0.75rem 1rem;
    width: 100%;
    text-align: left;
    border-radius: 6px;
  }

  .navbar-links a:hover {
    background: var(--background-secondary);
    opacity: 1;
  }

  .navbar {
    backdrop-filter: none;
    background: var(--background-primary);
  }

  /* Adjust the active indicator for mobile */
  .navbar-links a.active::after {
    display: none;
  }

  .navbar-links a.active {
    background: var(--background-secondary);
    color: var(--accent-primary);
  }
} 