.social-links {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.social-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: var(--background-secondary);
  color: var(--text-primary);
  text-decoration: none;
  border-radius: 12px;
  transition: all var(--transition-speed) ease;
  border: 1px solid var(--border-color);
}

.social-link:hover {
  transform: translateY(-2px);
  background: var(--background-tertiary);
  border-color: var(--accent-primary);
}

.social-link svg {
  font-size: 1.5rem;
  transition: transform var(--transition-speed) ease;
}

.social-link:hover svg {
  transform: scale(1.1);
}

.social-text {
  font-weight: 500;
}

/* Search highlight animation */
.social-link.highlight-result {
  animation: socialHighlight 2s ease;
}

@keyframes socialHighlight {
  0%, 100% {
    transform: translateY(0);
    box-shadow: none;
  }
  50% {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px var(--shadow-color);
    border-color: var(--accent-primary);
    background: var(--background-tertiary);
  }
} 