.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  text-align: center;
  background: var(--background-primary);
  color: var(--text-primary);
}

.error-page h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.error-page p {
  margin-bottom: 2rem;
  color: var(--text-secondary);
}

.error-button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin: 0.5rem;
}

.error-button:not(.secondary) {
  background: var(--accent-primary);
  color: white;
}

.error-button.secondary {
  background: var(--background-secondary);
  color: var(--text-primary);
}

.error-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px var(--shadow-color);
} 