.search-container {
  position: relative;
}

.search-trigger {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: var(--background-secondary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-secondary);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  max-width: 600px;
  margin: 8px auto;
}

.search-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 1000;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 80px;
  animation: fadeIn 0.2s ease;
}

.search-modal {
  width: 90%;
  max-width: 600px;
  background: var(--background-primary);
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  animation: slideDown 0.2s ease;
  overflow: hidden;
}

.search-input-wrapper {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
  gap: 12px;
}

.search-icon {
  color: var(--text-secondary);
  font-size: 16px;
}

input {
  flex: 1;
  border: none;
  background: none;
  font-size: 16px;
  color: var(--text-primary);
  outline: none;
  padding: 0;
}

input::placeholder {
  color: var(--text-secondary);
}

.clear-button {
  padding: 4px;
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.clear-button:hover {
  opacity: 1;
}

.search-results {
  max-height: 60vh;
  overflow-y: auto;
  padding: 8px;
}

.search-result {
  width: 100%;
  text-align: left;
  padding: 12px;
  border: none;
  background: none;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 8px;
}

.search-result:hover {
  background: var(--background-secondary);
}

.result-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.result-title {
  font-weight: 500;
  color: var(--text-primary);
}

.result-subtitle {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.result-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.tag {
  font-size: 0.8rem;
  padding: 2px 8px;
  background: var(--accent-primary-transparent);
  color: var(--accent-primary);
  border-radius: 4px;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apple-style keyboard shortcut */
kbd {
  padding: 2px 6px;
  font-family: -apple-system, system-ui;
  font-size: 12px;
  background: var(--background-tertiary);
  border-radius: 4px;
  border: 1px solid var(--border-color);
  box-shadow: 0 1px 1px var(--shadow-color);
}

/* Empty state */
.no-results {
  padding: 32px 16px;
  text-align: center;
  color: var(--text-secondary);
}

.result-type {
  font-size: 12px;
  color: var(--accent-primary);
  text-transform: capitalize;
  margin-bottom: 4px;
}

.search-section {
  padding: 8px 0;
}

.search-section:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.search-section-header {
  padding: 8px 12px;
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  background: var(--background-primary);
  position: sticky;
  top: 0;
  z-index: 1;
} 