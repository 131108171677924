.home-content {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  animation: fadeIn 0.5s ease;
  height: calc(100vh - var(--nav-height) - 2rem);
}

.profile-section {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 0.5rem;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid var(--background-secondary);
  box-shadow: 0 8px 24px var(--shadow-color);
}

.profile-text {
  flex: 1;
  min-width: 0;
}

.links-container {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
}

/* Modify SectionBox styles when inside profile-text */
.profile-text :global(.section-box) {
  padding: 1rem;
}

.profile-text :global(.section-box h2) {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.profile-text :global(.section-box p) {
  font-size: 0.95rem;
  line-height: 1.4;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes imageReveal {
  from {
    opacity: 0;
    transform: scale(0.8) rotate(-10deg);
    filter: blur(10px);
  }
  to {
    opacity: 1;
    transform: scale(1) rotate(0);
    filter: blur(0);
  }
}

@media (max-width: 768px) {
  .profile-section {
    flex-direction: column;
    text-align: center;
  }

  .home-content {
    height: auto;
    min-height: calc(100vh - var(--nav-height) - 2rem);
  }

  .profile-image {
    width: 120px;
    height: 120px;
  }
} 