:root {
  /* Light theme */
  --background-primary: #ffffff;
  --background-secondary: #f5f5f7;
  --background-tertiary: #ffffff;
  --text-primary: #1d1d1f;
  --text-secondary: #86868b;
  --accent-primary: #0071e3;
  --accent-gradient: linear-gradient(135deg, #0071e3, #40adff);
  --border-color: rgba(0, 0, 0, 0.1);
  --shadow-color: rgba(0, 0, 0, 0.1);
  --nav-height: 64px;
  --section-border-radius: 20px;
  --transition-speed: 0.3s;
  --accent-primary-rgb: 66, 153, 225; /* Example blue color */
  --accent-primary: rgb(var(--accent-primary-rgb));
  --accent-primary-transparent: rgba(var(--accent-primary-rgb), 0.1);
  --accent-primary-light: rgba(var(--accent-primary-rgb), 0.2);
}

[data-theme='dark'] {
  --background-primary: #000000;
  --background-secondary: #1d1d1f;
  --background-tertiary: #2d2d2f;
  --text-primary: #f5f5f7;
  --text-secondary: #86868b;
  --accent-primary: #2997ff;
  --accent-gradient: linear-gradient(135deg, #2997ff, #2fb5ff);
  --border-color: rgba(255, 255, 255, 0.1);
  --shadow-color: rgba(0, 0, 0, 0.3);
  --accent-primary-rgb: 99, 179, 237; /* Lighter blue for dark mode */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
}

body {
  background-color: var(--background-primary);
  color: var(--text-primary);
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  transition: background-color var(--transition-speed) ease,
              color var(--transition-speed) ease;
  line-height: 1.5;
}

#root {
  height: 100%;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  animation: fadeIn 0.5s ease;
  min-height: 100vh;
  position: relative;
  contain: paint;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.page-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  background: linear-gradient(to right, var(--accent-primary), var(--accent-secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

@media (max-width: 768px) {
  .main-content {
    padding: 1rem;
  }
  
  .page-title {
    font-size: 2rem;
  }
} 

@keyframes highlightFade {
  0%, 50% { background-color: var(--accent-primary); }
  100% { background-color: transparent; }
}

.highlight-result {
  transform: scale(1.01);
  position: relative;
  z-index: 1;
}

/* Project highlight */
.project-card.highlight-result {
  transform: scale(1.02);
  box-shadow: 0 0 0 2px var(--accent-primary);
}

/* Role highlight */
.role-section.highlight-result {
  background: var(--background-tertiary);
  border-left: 2px solid var(--accent-primary);
  animation: roleHighlight 2s ease-out;
}

/* Skill highlight */
.skill-card.highlight-result {
  transform: scale(1.1);
  box-shadow: 0 0 0 2px var(--accent-primary);
}

@keyframes highlightPulse {
  0% {
    background: var(--background-secondary);
    border-color: var(--border-color);
  }
  50% {
    background: var(--background-tertiary);
    border-color: var(--accent-primary);
  }
  100% {
    background: var(--background-secondary);
    border-color: var(--border-color);
  }
}

@keyframes roleHighlight {
  0%, 100% {
    background: transparent;
    border-left-color: transparent;
  }
  50% {
    background: var(--background-tertiary);
    border-left-color: var(--accent-primary);
  }
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}

.project-detail,
.role-section {
  transition: transform var(--transition-speed) ease;
}

.highlight-result {
  transform: scale(1.01);
}

/* Add consistent focus styles */
.searchable-component:focus-within {
  outline: 2px solid var(--accent-primary);
  outline-offset: 2px;
}

/* Ensure consistent spacing for search results */
.search-results {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.search-result-item {
  padding: 0.75rem;
  border-radius: 8px;
  background: var(--background-secondary);
  border: 1px solid var(--border-color);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.search-result-item:hover {
  background: var(--background-tertiary);
  transform: translateX(4px);
}

.search-highlight {
  animation: highlight 2s ease-out;
}

@keyframes highlight {
  0% {
    background-color: var(--highlight-color);
    transform: scale(1.02);
  }
  100% {
    background-color: transparent;
    transform: scale(1);
  }
}