.resume-content {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 1rem;
  animation: fadeIn 0.5s ease;
}

.experience-section {
  margin-bottom: var(--section-spacing);
}

.timeline {
  position: relative;
  padding-left: 2rem;
}

.timeline::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  background: var(--border-color);
}

.role-box {
  position: relative;
  background: var(--background-secondary);
  border-radius: 16px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  transition: all var(--transition-speed) ease;
  border: 1px solid var(--border-color);
}

.role-box::before {
  content: '';
  position: absolute;
  left: -2rem;
  top: 2rem;
  width: 1rem;
  height: 1rem;
  background: var(--background-primary);
  border: 2px solid var(--accent-primary);
  border-radius: 50%;
}

.role-box.current {
  border-color: var(--accent-primary);
}

.role-box.current::before {
  background: var(--accent-primary);
}

.role-box:hover {
  transform: translateX(8px);
  box-shadow: 0 4px 12px var(--shadow-color);
}

.company-logo {
  width: 56px;
  height: 56px;
  border-radius: 8px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.company-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.company-info {
  flex: 1;
}

.company-name {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.role-title {
  font-size: 1.1rem;
  color: var(--text-secondary);
  margin: 0.5rem 0;
}

.role-meta {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.bullet-points {
  list-style: none;
  padding-left: 0;
  margin-top: 1rem;
}

.bullet-points li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
}

.bullet-points li::before {
  content: "•";
  position: absolute;
  left: 0.25rem;
  color: var(--accent-primary);
}

.highlights {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 0.75rem 0;
}

.highlight-tag {
  background: var(--background-tertiary);
  color: var(--accent-primary);
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.85rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .timeline {
    padding-left: 1.5rem;
  }

  .role-box {
    padding: 1rem;
  }

  .role-box::before {
    left: -1.5rem;
    width: 0.75rem;
    height: 0.75rem;
  }

  .company-name {
    font-size: 1.1rem;
  }

  .role-title {
    font-size: 1rem;
  }
} 