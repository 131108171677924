.header-search-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 8px;
  border-radius: 6px;
  background: var(--background-secondary);
  border: 1px solid var(--border-color);
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.search-icon {
  font-size: 14px;
}

.shortcut {
  font-size: 12px;
  font-family: -apple-system, system-ui;
  padding: 2px 4px;
  border-radius: 4px;
  background: var(--background-tertiary);
  border: 1px solid var(--border-color);
}

.header-search-button:hover {
  background: var(--background-tertiary);
  transform: translateY(-1px);
}

@media (max-width: 768px) {
  .header-search-button {
    padding: 6px;
  }
  
  .shortcut {
    display: none;
  }
} 