.projects-content {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 1rem;
  animation: fadeIn 0.5s ease;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: 24px;
  padding: 24px 0;
}

.project-card {
  background: var(--background-secondary);
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
}

/* Header section */
.project-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid var(--border-color);
}

.project-header h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: var(--text-primary);
}

.project-actions {
  display: flex;
  gap: 12px;
}

.project-action {
  color: var(--text-secondary);
  font-size: 20px;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-action:hover {
  color: var(--accent-primary);
  background: var(--accent-primary-transparent);
}

/* Preview section */
.project-preview {
  position: relative;
  aspect-ratio: 16/9;
  overflow: hidden;
  background: var(--background-tertiary);
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-card:hover .project-image {
  transform: scale(1.05);
}

/* Content section */
.project-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
}

.project-content p {
  margin: 0;
  color: var(--text-secondary);
  font-size: 15px;
  line-height: 1.5;
}

.project-tech {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tech-tag {
  font-size: 13px;
  padding: 4px 12px;
  border-radius: 8px;
  background: var(--accent-primary-transparent);
  color: var(--accent-primary);
  font-weight: 500;
}

/* Hover effects */
.project-card {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.project-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  border-color: var(--accent-primary);
}

/* Search highlight animation */
.searchable-component[data-type="PROJECT"].search-highlight .project-card {
  animation: projectHighlight 2s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes projectHighlight {
  0% {
    transform: scale(1);
    border-color: var(--border-color);
  }
  20% {
    transform: scale(1.02);
    border-color: var(--accent-primary);
    box-shadow: 0 8px 32px rgba(var(--accent-primary-rgb), 0.2);
  }
  100% {
    transform: scale(1);
    border-color: var(--accent-primary);
    box-shadow: 0 4px 16px rgba(var(--accent-primary-rgb), 0.15);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 16px 0;
  }

  .project-header {
    padding: 12px 16px;
  }

  .project-header h3 {
    font-size: 16px;
  }

  .project-content {
    padding: 16px;
  }

  .project-content p {
    font-size: 14px;
  }

  .tech-tag {
    font-size: 12px;
    padding: 3px 10px;
  }
} 

.project-action svg[data-icon="youtube"] {
  color: #ff0000;  /* YouTube red */
}

.project-action:hover svg[data-icon="youtube"] {
  color: #ff0000;
  opacity: 0.8;
} 