.searchable-component {
  position: relative;
  transition: all 0.3s ease;
}

.searchable-component.search-highlight {
  position: relative;
  z-index: 1;
}

.searchable-component.search-highlight::before {
  content: '';
  position: absolute;
  inset: -4px;
  background: var(--accent-primary);
  opacity: 0;
  border-radius: inherit;
  z-index: -1;
  animation: highlightPulse 2s ease-out;
}

@keyframes highlightPulse {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  50% {
    opacity: 0.15;
    transform: scale(1.02);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

/* Skill-specific highlight styles */
.searchable-component[data-type="SKILL"].search-highlight .skill-item {
  animation: skillHighlight 2s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes skillHighlight {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 var(--accent-primary);
  }
  20% {
    transform: scale(1.05);
    box-shadow: 0 0 0 4px var(--accent-primary);
    background: var(--background-tertiary);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 var(--accent-primary);
    background: var(--background-secondary);
  }
}

/* Maintain other component type styles */
.searchable-component[data-type="PROJECT"].search-highlight .project-card {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px var(--shadow-color);
}

.searchable-component[data-type="EXPERIENCE"].search-highlight .role-box {
  transform: translateX(8px);
  border-color: var(--accent-primary);
} 