.skills-content {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 1rem;
  animation: fadeIn 0.5s ease;
}

.section-title {
  font-size: 1.5rem;
  color: var(--text-primary);
  margin-bottom: 1rem;
  font-weight: 600;
}

.skill-section {
  margin-bottom: 2.5rem;
}

.skill-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 1rem;
}

.skill-card {
  position: relative;
  background: var(--background-secondary);
  border-radius: 12px;
  padding: 1.25rem;
  border: 1px solid var(--border-color);
  transition: all 0.2s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.skill-icon-wrapper {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: var(--background-tertiary);
  transition: background-color 0.2s ease;
}

.skill-icon {
  width: 28px;
  height: 28px;
}

.skill-content {
  text-align: center;
}

.skill-name {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

/* Simple hover state */
.skill-card:hover {
  background: var(--background-tertiary);
  border-color: var(--accent-primary);
}

/* Search highlight animation */
.searchable-component[data-type="SKILL"].search-highlight .skill-card {
  animation: skillHighlight 2s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes skillHighlight {
  0% {
    transform: scale(1);
    border-color: var(--border-color);
  }
  20% {
    transform: scale(1.02);
    border-color: var(--accent-primary);
    box-shadow: 0 8px 32px rgba(var(--accent-primary-rgb), 0.2);
  }
  100% {
    transform: scale(1);
    border-color: var(--accent-primary);
    box-shadow: 0 4px 16px rgba(var(--accent-primary-rgb), 0.15);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .skill-grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 0.75rem;
  }

  .skill-card {
    padding: 1rem;
  }

  .skill-icon-wrapper {
    width: 40px;
    height: 40px;
  }

  .skill-icon {
    width: 24px;
    height: 24px;
  }

  .skill-name {
    font-size: 0.9rem;
  }
} 